import React, { useEffect, useState, Fragment } from "react";
import { Button, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useNavigate } from "react-router-dom";
import LogOutModal from "../subcomponents/LogOutModal";
import DashboardSideBar from "./DashboardSideBar";

const Header = () => {
    const dashboardopen = true;

    const [activeTab, setActiveTab] = useState("dashboard");

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    return (
        <div className="Hamburger_icon_wrapper">
        <div>
          <img
            style={{ width: "auto", height: "33px" }}
            src="/assets/images/OttonomyLogo.png"
            className="logo-btm"
            alt="Ottonomy Logo"
          />
        </div>
        {["right"].map((anchor) => (
          <Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ color: "#282F42" }}
              id="Responsive_drawer"
            >
              <DashboardSideBar  dashboardopen={dashboardopen}
              active={activeTab} setActiveTab={setActiveTab} smallScreen={true}/>
            </Drawer>
          </Fragment>
        ))}
      </div>
    )
};

export default Header;
