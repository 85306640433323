import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import FleetPagerobotPage from "./FleetPagerobotPage";
import FleetPageMapPage from "./FleetPageMapPage";

import OrderListNew from "../subcomponents/OrderListNew";
import VideoStream from "./VideoStream";
import Unauthorized from "./Unauthorized";
import { useSelector, useDispatch } from "react-redux";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import {
  useHasPermissionInFleet,
  useHasPermissionInAnyFleet,
} from "../customHooks/PermissionHooks";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OrdersReports from "../subcomponents/OrdersReports";
import Missions from "../subcomponents/Missions";

function FleetPageTopBar(props) {
  const { fleetList } = useSelector((state) => state.fleetList);
  const [topbar, settopbar] = useState("");
  const [selectedTabName, setSelectedTabName] = useState(topbar);
  const [googlemap, setgooglemap] = useState(true);
  const [selectedFleet, setSelectedFleet] = useState();

  const fleetData = props.fleetData;
  const hasPermissionForMonitor = useHasPermissionInAnyFleet("fleet-monitor");
  const hasPermissionForOrders = useHasPermissionInAnyFleet("order-managment");
  const hasPermissionForVideoStream =
    useHasPermissionInAnyFleet("fleet-stream");
  const hasPermissionForMissions = useHasPermissionInAnyFleet("missions");
  const emailId = localStorage.getItem("useremail");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handletoplink = (value) => {

    const splitStr = value.split("-");
    let name =null;
    if(splitStr.length > 1){
      value = splitStr[0];
      name= splitStr[1];
    }

    settopbar(value);

    if(name){
      setSelectedTabName(name)
    }
  };

  const handlemaptoggle = () => {
    setgooglemap(!googlemap);
  };

  useEffect(() => {
    if (!fleetList) {
      setSelectedFleet(fleetData?.fleetName);
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId, fleetData?.fleetName]);

  useEffect(() => {
    if (hasPermissionForMissions) {
      settopbar("missions");
      setSelectedTabName("Missions");
    }
    if (hasPermissionForOrders) {
      settopbar("orders");
      setSelectedTabName("Order Dashboard");
    } else if (hasPermissionForVideoStream) {
      settopbar("videostream");
      setSelectedTabName("Collective Video");
    } else if (hasPermissionForMonitor) {
      settopbar("monitor");
      setSelectedTabName("Monitor");
    }
  }, [
    selectedFleet,
    hasPermissionForMonitor,
    hasPermissionForOrders,
    hasPermissionForVideoStream,
    hasPermissionForMissions,
  ]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  return (
    <>
      <div className="Dashboard_page_rightSide_content_heading">
        <div className="flex items-center justify-center h-full cursor-pointer">
          <p className="FleetView_page_haeding_tag">
            {" "}
            <span
            style={{ fontSize: "19px" }}
              onClick={() => {
                navigate("/fleetPage");
              }}
            >
              Fleet
            </span>{" "}
            <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            <span className="FleetView_page_Heading_fleetName"  style={{ fontSize: "19px" }}
            >
             {props.fleetData?.fleetName}
            </span>
          </p>
        </div>
        <div>
          <div className="FleetPage_top_bar_left_side">
            {hasPermissionForMonitor && (
              <div
                className={topbar === "monitor" ? "top_bar_active" : ""}
                onClick={() => handletoplink("monitor-Monitor")}
              >
                {" "}
                Monitor
              </div>
            )}
            {hasPermissionForOrders && (
              <div
                className={topbar === "orders" ? "top_bar_active" : ""}
                onClick={() => handletoplink("orders")}
              >
                {" "}
                Order Dashboard
              </div>
            )}
            {hasPermissionForMissions && (
              <div
                className={topbar === "missions" ? "top_bar_active" : ""}
                onClick={() => handletoplink("missions")}
              >
                {" "}
                Missions
              </div>
            )}
            {hasPermissionForVideoStream && (
              <div
                className={topbar === "videostream" ? "top_bar_active" : ""}
                onClick={() => handletoplink("videostream")}
              >
                {" "}
                Collective Videos{" "}
              </div>
            )}
          </div>
          <Dropdown
            onSelect={(eventKey) => {
              handletoplink(eventKey);
            }}
            id="menu_dropdown_select"
          >
            <Dropdown.Toggle id="dropdown-basic">{selectedTabName}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              {hasPermissionForMonitor && (
                <Dropdown.Item key={"monitor"} eventKey={"monitor-Monitor"}>
                  Monitor
                </Dropdown.Item>
              )}
              {hasPermissionForOrders && (
                <Dropdown.Item key={"orders"} eventKey={"orders-Order Dashboard"}>
                  Order Dashboard
                </Dropdown.Item>
              )}
              {hasPermissionForMissions && (
                <Dropdown.Item key={"missions"} eventKey={"missions-Missions"}>
                  Missions
                </Dropdown.Item>
              )}
              {hasPermissionForVideoStream && (
                <Dropdown.Item key={"videostream"} eventKey={"videostream-Collective Video"}>
                  Collective Video
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {fleetData &&(
      <>
      {topbar === "monitor" ? (
        <FleetPagerobotPage
          googlemap={googlemap}
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "maps" ? (
        <FleetPageMapPage
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "videostream" ? (
        <VideoStream
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "reports" ? (
        <OrdersReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "orders" ? (
        <OrderListNew
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "missions" ? (
        <Missions fleetData={props.fleetData ? props.fleetData : fleetData} />
      ) : (
        <Unauthorized />
      )}
      </>  
      )}
    </>
  );
}

export default FleetPageTopBar;
