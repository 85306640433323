



export default function MissionCard({
  index,
  mission,
}) {

  const destructureActions = (actions) => {
    let componentList = [];

    let newActions = actions.filter((item) => (item.actionType === "MOVELOCATION") )
    newActions.filter((item) => (item.message!=="MOVEHOME")).map((action, index) => {
      // let startTimeString = action.startTime.substring(0,2)+":"+action.startTime.substring(2,4);
      let startTimeString = index<actions.length-1 ?newActions[index+1]?.startTime?.substring(0,2)+":"+newActions[index+1]?.startTime?.substring(2,4):"";

      let finishTimeString = action?.finishTimeCalculated?.substring(0,2)+":"+action?.finishTimeCalculated?.substring(2,4);
    

      componentList.push(
        <div key={`action-${index}`} className="flex flex-col justify-center items-center">
              <div className="bg-white rounded-full w-[10px] h-[10px]"></div>
              <div className="flex flex-col items-center">
                <div className="text-sm">{action?.locationId?.locationName}</div>
                <div className="text-[12px]">{finishTimeString}</div>
              </div>
        </div>)


      // componentList.push(
      //   <div className="flex flex-col justify-center items-center relative">
      //         <div className="bg-white rounded-full w-[10px] h-[10px]"></div>
      //         <div className="w-[130px] flex justify-between absolute top-[-16px]">
      //           <div className="text-[12px]">{finishTimeString}</div>
      //           {(index<newActions.length-1)? <div className="text-[12px]">{startTimeString}</div>:""}
      //         </div>

      //         <div className="flex flex-col items-center">
      //           <div className="text-sm">{action?.locationId?.locationName}</div>
      //         </div>
      //   </div>)
    })
    return componentList;
  }

  return (
      <>
      <div className="flex flex-col gap-3 bg-[#1C1E2A] pl-4 pr-4 pb-4 pt-2">
        <div className="font-bold">{mission.name}</div>
        <div className="mt-2">
          <div  className="flex justify-center">
        <div className="w-full border-t border border-1 border-stone-300 absolute mt-1 z-0"></div>
            </div>
          <div className="flex justify-around z-1">
            {destructureActions(mission.actions)}
          </div>
        </div>
      </div>
      </>
  );
}
