import React, { useState } from "react";
import DashboardSideBar from "../subcomponents/DashboardSideBar";
import Header from "../subcomponents/Header";
import { useSelector } from "react-redux";

const Layout = ({ children, dashboardopen, activeTab, setActiveTab ,hideNavigation,hideLogout }) => {
    // const [activeTab, setActiveTab] = useState("dashboard");

  let { sidebarcollapse } = useSelector((state) => state.login);
  if(hideNavigation){
    sidebarcollapse=true
  }
  return (
    <div className="flex h-screen">
      {!hideNavigation&&
      <>
      <div
        className={` hidden relative midLg:block ${
          sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"
          }`}
      >
        <DashboardSideBar dashboardopen={dashboardopen} active={activeTab} setActiveTab={setActiveTab} hideLogout={hideLogout}/>
      </div>
        </>
        }
      <div className="flex-1 overflow-y-auto">
      {!hideNavigation&&
        <Header />
      }
        <main className={`h-full ${
              sidebarcollapse ? "collapseWidth" : "w-full"
            }`}>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
