import { useEffect, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";
import { getAllMessages, getAllUserGroups, sendMessageToUserGroups } from "../API";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import { FaTimes} from "react-icons/fa";
import { dispatch } from "d3";
import { userActions } from "../redux/slice/userSlice";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";

const sendCommunicationModalStyle = {
    position: "absolute",
    // bottom: "-38%",
    bottom: "-320px",
    // right: "-12%",
    right: "-230px",
    transform: "translate(-50%, -50%)",
    bgcolor: "#282F42",
    border: "none",
    boxShadow: 24,
    borderRadius: "8px",
    height: "80%",
    maxWidth: "510px"
  };

export default function SendCommunicationCard ({fleetData}){
    const hasPermissionForSendCommunication = useHasPermissionInAnyFleet("send-communication");

    const [showCommunicationModal, setShowCommunicationModal] = useState(false);
    useEffect(() => {
    
        const response = getAllUserGroups({
          "fleetId": fleetData._id
        })
        response.then((res) => { 
          dispatch(userActions.setUserGroup(res));
        })
      }, [fleetData])
    const userGroupData = useSelector((state) => { 
        return state.userReducer.userGroup;
    })
    const [message, setMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Please Enter the message...");
    const prefill = ["Ottobot Service is Unavailable Today","Ottobot Service is back online"];
    
    const [selectedUserGroups, setSelectedUserGroups] = useState(userGroupData);
    const [sentMessages, setSentMessages] = useState([])
    const [receivedMessages, setReceivedMessages] = useState([]);

    useEffect(() => {
        const intervalId = setInterval(() => getAllMessages().then((res) => setReceivedMessages(res.data.data)), 2000);
        
        return () => clearInterval(intervalId);
    },[])

    useEffect(() => {
        setSelectedUserGroups(userGroupData)
    },[userGroupData])

    const selectUsers = (userGroup, operation) => {
        if(operation===1){
            setSelectedUserGroups((prev) => [...prev, userGroup]);
        }
        else{
            setSelectedUserGroups((prev) => prev.filter((item) => item.name !== userGroup.name));
        }
    }

    const selectAllUsers = () => {
       if(selectedUserGroups.length===userGroupData.length){
           setSelectedUserGroups([]);
       }
       else{
           setSelectedUserGroups(userGroupData);
       }
    }
    
    const sendMessage = () => {
        
        if(message!=="" && selectedUserGroups.length>0){
            sendMessageToUserGroups({
                fleetId: fleetData.fleetId,
                smsContent: message,
                userGroups: selectedUserGroups.length===userGroupData.length?[]:selectedUserGroups,
            }).then((res) => {
                setMessage("")
                toast.success("Message sent successfully");
            }).catch((e) => {
                console.log(e, "hello error")
                toast.error("Something went wrong");
            })
        }
        else{
            if(message===""){
                setErrorMessage("Please Enter the message...")
            }
            else if(selectedUserGroups.length===0){
                setErrorMessage("Please Select at least 1 group")
            }
            setShowError(true);
        }
    }

    const showMessages = () => {
        const messages = [];

        let i=0,j=0;

        while(i<sentMessages.length && j<receivedMessages.length){


            let time1 = new Date(sentMessages[i].createdAt);
            let time2 = new Date(receivedMessages[j].createdAt);

            if(time1<time2){
                // Format the date to a human-readable string
                const humanReadableDate = time1.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    // second: '2-digit',
                    hour12: true,
                    timeZone: 'UTC',
                    // timeZoneName: 'short',
                });
    
                let message1 = (<div className="w-full mb-4">
                    <div className="break-words max-w-[100%] mt-2 ml-auto w-fit px-2 py-1 rounded-l-xl border-[1px] border-gray-600">{sentMessages[i].message}</div>
                    <div className="text-right text-[12px]">{humanReadableDate}</div>
                </div>)

    
                messages.push(message1);
                i++;
            }
            else{
                const humanReadableDate = time2.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    // second: '2-digit',
                    hour12: true,
                    timeZone: 'UTC',
                    // timeZoneName: 'short',
                });
    
                let message1 = (<div className="w-full mb-4">
                    <div className="break-words max-w-[100%] mt-2 mr-auto w-fit px-2 py-1 rounded-r-xl border-[1px] border-gray-600">{receivedMessages[j].message}</div>
                    <div className="text-left max-w-[100%] text-[12px]">{humanReadableDate}</div>
                </div>)
    
                messages.push(message1);
                j++;
            }
        } 
        while(i<sentMessages.length){
            let time1 = new Date(sentMessages[i].createdAt);

            const humanReadableDate = time1.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                // second: '2-digit',
                hour12: true,
                timeZone: 'UTC',
                // timeZoneName: 'short',
            });


            let message1 = (<div className="w-full mb-4">
                <div className="break-words max-w-[100%] mt-2 ml-auto w-fit px-2 py-1 rounded-l-xl border-[1px] border-gray-600">{sentMessages[i].message}</div>
                <div className="text-right text-[12px]">{humanReadableDate}</div>
            </div>)

            messages.push(message1);
            i++;
        } 
        while( j<receivedMessages.length){
            let time2 = new Date(receivedMessages[j].createdAt);

            const humanReadableDate = time2.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                // second: '2-digit',
                hour12: true,
                timeZone: 'UTC',
                // timeZoneName: 'short',
            });

            let message1 = (<div className="w-full mb-4">
                <div className="break-words max-w-[100%] mt-2 mr-auto w-fit px-2 py-1 rounded-r-xl border-[1px] border-gray-600">{receivedMessages[j].message}</div>
                <div className="text-left max-w-[100%] text-[12px]">{humanReadableDate}</div>
            </div>)

            messages.push(message1);
            j++;
        } 

        return messages;
    }

    return (
        <>
        {!showCommunicationModal && <div className="w-20 h-20 flex flex-row justify-center items-center bg-ottonomyBlue z-[100] rounded-full border-[2px] border-ottonomyBlue text-white  text-center fixed bottom-8 right-6 hover:cursor-pointer" onClick={() => setShowCommunicationModal(true)}>
          <img alt="communication" src="/assets/images/message-icon.svg" className="w-10 h-10" style={{ filter: 'invert(1)' }}/>
        </div>}
        <Modal open={showCommunicationModal} onClose={() => setShowCommunicationModal(false)}  backdrop="static"
      keyboard="false"  disableAutoFocus={true}>
        <Box sx={sendCommunicationModalStyle}>

            <div className="flex text-white bg-[#282F42] p-3 rounded-lg h-full min-w-[506px]">
            <div className="relative flex flex-col gap-2 min-h-full max-h-full w-full">
                <div className="flex flex-row justify-between w-full items-center pb-2">
                    <span className=" bg-[#282f41] flex items-center text-lg font-semibold font-poppins">
                    Communication
                    </span>
                    <div className="hover:cursor-pointer" onClick={() => setShowCommunicationModal(false)}>
                        {/* <FaWindowClose/>
                        <FaTimesCircle/> */}
                        <FaTimes/>
                    </div>
                </div>
                <div className="flex flex-col gap-3  h-full">
                    
                    <div className={`w-full h-[calc(100%-45px)]`}>
                        {/* Chats */}
                        
                        <div className={`rounded-lg p-3 ${hasPermissionForSendCommunication?`${showError?"h-[calc(100%-132px)]":"h-[calc(100%-122px)]"}` :"h-full"} bg-[#1C1E2A] mb-1 w-full`}>
                        {/* <div className={`${message===""?"h-[calc(100%-110px)]":"h-[calc(100%-82px)]"} w-full bg-[#1C1E2A]`}> */}
                    <div className={`w-full overflow-y-auto ${(message==="" && hasPermissionForSendCommunication)?"h-[calc(100%-36px)] mb-2":"h-full"}`}>
                            {showMessages()}
                        </div>
                            {(message==="" && hasPermissionForSendCommunication) && <div className={`flex flex-row items-center gap-2 overflow-auto ${message===""?"":"animate-slideOut"}`} >
                                {prefill.map((item, index) => {
                                    return (
                                        <div key={`msg-${index}`} className="px-2 py-1 border-[1px] overflow-hidden min-w-fit hover:cursor-pointer text-[12px] text-ellipsis border-solid rounded-lg bg-gray-300 border-gray-300 text-black" onClick={() => {setMessage(prefill[index])
                                            setShowError(false)
                                        }}>
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>
                        {hasPermissionForSendCommunication &&<div>
                        <div className="flex flex-row items-center w-full min-h-[45px] mb-2">
                            <div className="mr-3 w-[30px]">To :</div>
                            <div className="max-h-full w-full flex flex-row gap-2 overflow-x-scroll">
                                <div className={`max-h-[31px] min-h-[31px]  text-center hover:cursor-pointer  py-1 px-2 rounded-lg  ${selectedUserGroups.length===userGroupData.length?"bg-ottonomyBlue":"bg-gray-300 text-gray-500"}`} onClick={selectAllUsers}>All</div>
                                {/* <div className="flex flex-row gap-2 max-h-full"> */}
                                    {userGroupData.map((userGroup, index) => {
                                        return (
                                            <div className={`min-w-fit max-h-[31px] min-h-[31px] px-2 py-1 rounded-lg hover:cursor-pointer ${selectedUserGroups.filter((item) => item.name===userGroup.name).length>0?"bg-ottonomyBlue":"bg-gray-300 text-gray-500"}`} onClick={() => selectUsers(userGroup, selectedUserGroups.filter((item) => item.name===userGroup.name).length>0?0:1)}>
                                                {userGroup.name}
                                            </div>
                                        )
                                    })}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="w-full flex flex-col">
                            <div className="w-full flex flex-row">
                                <textarea required className="w-full px-2 pt-1 text-decoration-none max-h-[40px] focus:outline-none resize-none bg-[#1C1E2A] rounded-lg rounded-r-none text-[12px]" placeholder="Enter your message" value={message} onChange={e => {
                                        setMessage(e.target.value)
                                        setShowError(false);
                                        }} />
                                    <div className="flex justify-center items-center ml-auto text-center bg-ottonomyBlue px-4 hover:cursor-pointer rounded-lg w-fit" onClick={sendMessage}><SendIcon/></div>
                            </div>
                            { showError && <p className="text-red-500 mx-auto">{errorMessage}</p>}
                        </div>
                        </div>}
                    </div>
                    
                </div>
            </div>
            </div>
        </Box>
           
        </Modal>
      </>
    )
}